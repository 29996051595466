@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-nav {
  .nav-left {
    .nav-item {
      @media (max-width: 550px) {
        margin-left: 0.15rem;
        margin-right: 0.15rem;
        width: 40%;
        overflow: hidden;
        height: 18px;
        text-overflow: ellipsis;
      }
      > a {
        color: colors.$color-grey-default;
        position: relative;
        margin-left: 0.75rem;
        margin-right: 0.75rem;
        font-weight: typography.$font-weight-medium;
        padding: 0.5rem 0.5rem;
        &:hover {
          text-decoration: none !important;
          color: colors.$color-blue-hover !important;
        }
        &.active {
          color: colors.$color-blue-primary;
          font-weight: typography.$font-weight-bold;
          &::after {
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px;
            border-color: transparent;
            border-bottom-color: colors.$color-grey-light;
            left: calc(50% - 10px);
            bottom: -20px;
            transition: all 0.3s ease-in-out;
          }
        }
        @media (max-width: 1180px) {
          padding: 0rem;
          &.active:after {
            bottom: -28px;
          }
        }
        @media (max-width: 768px) {
          font-size: 12px;
          margin-left: 0.15rem;
          margin-right: 0.15rem;
        }
      }
    }
  }
  .nav-right {
    .nav-link {
      color: colors.$color-grey-default;
      font-weight: typography.$font-weight-medium;
      padding: 0.5rem 0rem;
      margin: 0.5rem 1.5rem;
      @media (max-width: 768px) {
        margin: 0.5rem;
      }
      &:hover {
        text-decoration: none !important;
        color: colors.$color-blue-hover;
      }
      &.active {
        color: colors.$color-blue-primary;
      }
    }
    .dropdown-menu {
      top: 15px !important;
      z-index: 1040;
      &::before {
        right: 39px;
        @media (max-width: 768px) {
          right: 22px;
        }
      }
      &::after {
        right: 38px;
        @media (max-width: 768px) {
          right: 21px;
        }
      }
    }
  }
  &__admin-badge {
    border-radius: 12px;
    background-color: colors.$color-admin;
    color: colors.$color-blue-primary;
    font-size: 13px;
    font-weight: typography.$font-weight-bold;
    padding: 2px 10px;
  }
}
