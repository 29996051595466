@use "../1-settings/colors";
@use "../1-settings/typography";

._badge {
  padding: 5px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
  width: 60px;
  text-align: center;
  display: inline-block;
}
.modal-body ._badge {
  min-width: 100px;
}

._badge-white {
  background-color: #ffffff;
  color: colors.$color-blue-primary;
  border: 1px solid colors.$color-blue-primary;
}

.badge-extra {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 16px;
  width: 2rem;
  background-color: colors.$color-class-badge;
  > span {
    text-align: center;
    color: colors.$color-grey-text;
    font-weight: typography.$font-weight-medium;
  }
}
