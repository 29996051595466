@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-badge {
  &__mandatory {
    color: white;
    background-color: colors.$color-requests-mandatory;
  }

  &__important {
    color: white;
    background-color: colors.$color-requests-important;
  }

  &__friend {
    color: white;
    background-color: colors.$color-friendships;
  }

  &__is-met {
    &-mandatory {
      border: 2px solid colors.$color-requests-mandatory;
      color: colors.$color-requests-mandatory;
    }

    &-important {
      border: 2px solid colors.$color-requests-important;
      color: colors.$color-requests-important;
    }

    &-friend {
      border: 2px solid colors.$color-friendships;
      color: colors.$color-friendships;
    }
  }

  &__response-circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    font-weight: typography.$font-weight-medium;
    width: 16px;
    height: 16px;
    font-size: 10px;
  }

  &__no-friend-pref {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: colors.$color-friendships;
    width: 18px;
    height: 18px;
  }

  &__circle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    font-weight: typography.$font-weight-medium;
    width: 18px;
    height: 18px;
    font-size: 12px;

    &.not-met-friend {
      border: 2px solid colors.$color-friendships;
      color: colors.$color-friendships;
      width: 16px;
      height: 16px;
      font-size: 10px;
    }

    &.no-friend-pref {
      border: 2px solid colors.$color-friendships;
      color: colors.$color-friendships;
      width: 16px;
      height: 16px;
      font-size: 14px;
      font-weight: 900;
    }
  }
  &__circle-request {
    &-red {
      color: white;
      background-color: colors.$color-requests-mandatory;
    }
    &-yellow {
      color: white;
      background-color: colors.$color-requests-important;
    }
  }
}
