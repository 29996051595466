.c-student-modal {
  &__icon {
    position: absolute;
    top: 50%;
    z-index: 1060;
    color: rgba(255, 255, 255, 0.3);
    font-size: 40px;
    cursor: pointer;

    &--prev {
      left: -100px;
    }
    &--next {
      right: -100px;
    }
  }
  &__modal {
    max-height: 95vh;
    overflow-y: scroll;
    &__body {
      > * {
        padding: 1rem;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }
}
