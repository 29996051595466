@use "../../1-settings/colors";
@use "../../1-settings/typography";

$border-color: colors.$color-grey-border;

.c-students {
  &__total {
    color: colors.$color-total-text;
    font-family: "Montserrat" !important;
    font-size: 12px;
    font-weight: typography.$font-weight-medium;
    margin: 1rem;
  }
}
