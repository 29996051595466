.c-filtered-list {
  position: relative;

  &__input {
    padding: 6px 22px 6px 12px;
  }

  &__icon {
    position: absolute;
    top: 10px;
    right: 6px;
    font-size: 18px;
    cursor: pointer;
  }

  &__list {
    position: absolute;
    top: calc(100% + 3px);
    left: 0;
    width: 100%;
    max-height: 200px;
    z-index: 5;

    &__list-group {
      border: 1px solid #ccc;
      border-radius: 4px;
      max-height: 200px;
      overflow: auto;
      display: block;
      background-color: white;

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        background-color: rgb(214, 214, 214);
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-track {
        background-color: #ffffff;
      }

      &__list-group-item {
        border-width: 0;
        padding-top: 0.35rem;
        padding-bottom: 0.35rem;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
