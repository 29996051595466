@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-teachers-invite-table {
  &__header {
    color: rgba(52, 52, 52, 0.9);
    font-family: "Libre Franklin";
    font-size: 12px;
    font-weight: typography.$font-weight-bold;
  }

  &__data {
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 11px;

    &-container {
      margin: 0 -15px;
      border-top: 2px solid rgba(0, 0, 0, 0.2);
    }
  }
  &__send {
    color: colors.$color-blue-primary;
  }

  &__multiselect {
    width: 100%;
    font-size: 16px !important;
  }
}
