@use "../../../1-settings/colors";
@use "../../../1-settings/typography";

.c-student-modal-details {
  &__student-id {
    color: colors.$color-blue-primary;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: typography.$font-weight-medium;
  }

  &__student-id-generated {
    border-color: colors.$color-light-pink !important;
  }
}
