@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-reports-table-body {
  &__row {
    border-bottom: 1px solid colors.$color-grey-border;
    &:nth-of-type(odd) {
      background-color: white !important;
    }
    &:nth-of-type(even) {
      background-color: colors.$color-grey-table !important;
    }

    &--empty {
      border: none;
      opacity: 0;
    }
    &--divider {
      border: 1px solid colors.$color-grey-border;
    }
    &--total {
      border: 2px solid colors.$color-grey-border;
      font-family: "Libre Franklin", sans-serif;
      font-size: 13px;
      font-weight: typography.$font-weight-medium;
    }
    &__data {
      overflow-wrap: normal;
      padding: 5px;
      min-width: 90px;
      height: 40px;
      font-size: 13px;
      text-align: center;
      border-right: 1px solid colors.$color-grey-border;
      &:first-child {
        border-left: 1px solid colors.$color-grey-border;
      }
    }
    &__notes {
      white-space: pre-wrap;
    }
    &__class-label {
      font-family: "Libre Franklin", sans-serif;
      font-size: 12px;
      font-weight: typography.$font-weight-bold;
    }
  }
}
