@use "../../1-settings/typography";

.c-grade-modal {
  &__table-header {
    color: rgba(52, 52, 52, 0.9);
    font-family: "Libre Franklin";
    font-size: 14px;
    &__text {
      font-weight: typography.$font-weight-medium;
    }
  }
}
