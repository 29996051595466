@use "../../1-settings/colors";

.c-add-button {
  cursor: pointer;
  background-color: colors.$color-grey-ed;
  padding: 15px;
  border-radius: 4px;
  &__inactive {
    &:hover {
      text-decoration: none !important;
      cursor: default;
    }
  }
}
