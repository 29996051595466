@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-side-bar {
  &__timeline-container {
    // TODO: Work out why this is 304px and whether we can set it automatically
    width: 304px;
    height: 390px;
    overflow-y: scroll;
    overflow-x: hidden;
    // Work around a weird bug where all text in the dropdown becomes
    // selected after a few toggles. I can't figure out why this happens
    // so we'll just disable the ability to select the text.
    user-select: none;
  }

  &__timeline-item {
    color: colors.$color-grey-default;
    &:focus {
      // By default, Bootstrap's dropdown item sets the background
      // to grey when focusing. We don't want that.
      background-color: unset;
    }
  }

  &__timeline-sub-heading {
    font-size: 14px;
    color: colors.$color-grey-default;
  }

  &__pencil {
    color: colors.$color-blue-mid;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      transform: scale(1.25);
    }
  }

  &__clock {
    color: colors.$color-blue-mid;
    font-size: 25px;
    cursor: pointer;
  }

  &__blue-pin {
    color: colors.$color-blue-mid;
    font-size: 19px;
    cursor: pointer;
    margin-top: 2px;

    &:hover {
      transform: scale(1.25);
    }
  }

  &__grey-star {
    color: colors.$color-grey-mid;
    font-size: 20px;
    cursor: pointer;

    &:hover {
      transform: scale(1.25);
      color: colors.$color-blue-mid;
    }
  }

  &__count {
    color: #6e6e6e;
    margin: 0.5rem 0 0 0;
    cursor: pointer;
    font-size: 13px;
    margin-left: 0.5rem;
    padding-right: 2px;
    text-align: right;
    &--selected {
      color: colors.$color-blue-primary;
      font-weight: typography.$font-weight-bold;
    }
  }

  &__hide-button {
    position: relative;
    padding-left: 2px;
    width: 31px;
    height: 64px;
    left: 332px;
    top: 60px;
    z-index: 1020;
    background-color: colors.$color-grey-light;
    border-radius: 0 5px 5px 0;
    box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.1);
    font-size: 22px;
    &--close {
      transform: translate(-332px, 0);
    }
  }

  &__panel {
    top: -65px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 22rem;
    min-width: 22rem;
    background-color: colors.$color-grey-light;
    box-shadow: 2px 0 5px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-out;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;

    .logged-in & {
      height: calc(100vh - 72px); //72px is the height of the navbar
    }
    &--open {
    }
    &--close {
      display: none;
    }

    &__minor-actions-container {
      button {
        padding: 6px 10px;
      }
      button,
      button:active {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: colors.$color-blue-mid;
        border-color: colors.$color-blue-mid;
        background-color: transparent;
        box-shadow: none;
        &::after {
          border-width: 6px;
        }
      }
      &.show > button {
        box-shadow: none;
      }

      &__dropdown {
        .dropdown-menu {
          width: auto;
          min-width: 80px;
          top: 0px !important;
          button {
            background-color: #fff;
            padding: 6px 10px;
            color: colors.$color-blue-mid;
          }
          &::before,
          &::after {
            display: none;
          }
          a {
            color: colors.$color-blue-mid;
          }
        }
      }
    }

    &__divider-line {
      height: 1px;
      margin-left: -24px;
      margin-right: -24px;
      border-bottom: 1px solid #d7dbe0;
    }

    &__menu {
      padding: 25px 0px 40px 10px;
      display: flex;
      &__run,
      &__undo,
      &__redo {
        padding-right: 40px;
        user-select: none;
        span {
          font-weight: typography.$font-weight-medium;
          font-size: 16px;
        }
      }
    }

    &__grade-select {
      background: url("../../../images/up-down-arrow.svg") no-repeat 95% 50%;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      min-height: 36px;
    }

    &__characteristics-container {
      &__input {
        max-width: 174px;
        background: url("../../../images/up-down-arrow.svg") no-repeat 95% 50%;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        flex-grow: 1;
        min-height: 36px;
        width: 500px;
        background-color: #fff;
      }
    }

    &__no-student {
      margin: 25px 5px;
      font-size: 14px;
      font-weight: typography.$font-weight-medium;
    }

    &__read-only-badge {
      border-radius: 12px;
      background-color: colors.$color-read-only;
      color: colors.$color-blue-primary;
      font-size: 13px;
      font-weight: typography.$font-weight-bold;
      padding: 2px 10px;
    }
  }
}
