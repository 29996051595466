@use "../1-settings/colors";
@use "../1-settings/typography";

.student-upload-list {
  &__header {
    border-bottom: 2px solid colors.$color-grey-border;

    &__label {
      color: rgba(52, 52, 52, 0.9);
      font-family: "Libre Franklin";
      font-size: 12px;
      font-weight: typography.$font-weight-bold;
    }
  }
  &__item {
    &:hover {
      background-color: colors.$color-blue-lighter;
      .student-upload-list__item__label__pencil {
        opacity: 1;
      }
    }
    &__order {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: rgba(28, 54, 100, 0.3);
      color: colors.$color-white;
      font-family: "Libre Franklin";
      font-size: 13px;
      font-weight: typography.$font-weight-medium;
      text-align: center;
      line-height: 24px;
    }
    &__icon {
      color: rgba(0, 0, 0, 0.38);
      font-size: 14px;
      cursor: pointer;
      &--times {
        font-size: 19.2px;
      }
      &--arrow {
        font-size: 16px;
        cursor: default;
      }
      &--check-square {
        color: rgba(40, 167, 69, 1);
        font-size: 22px;
        cursor: pointer;
      }
    }
    &__label {
      color: colors.$color-grey-text;
      font-family: "Libre Franklin";
      font-size: 14px;
      font-weight: typography.$font-weight-medium;
      &__pencil {
        color: colors.$color-blue-mid;
        font-size: 20px;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}
