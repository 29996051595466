@use "../../1-settings/colors";

.c-toggle-badge {
  height: 20px;
  width: 75px;
  margin: 10px 5px;
  border-radius: 5px;
  border: 1px solid colors.$color-grey-mid;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  background-color: colors.$color-grey-ed;
  color: colors.$color-grey-text;
  &--admin-only {
    background-color: rgb(227, 239, 190);
  }
  &--tag-only {
    background-color: colors.$color-blue-lighter;
  }
}
