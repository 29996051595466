@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-solver-class {
  border: 1px solid colors.$color-grey-border;
  margin: 0.5rem;
  border-radius: 5px;

  &__header {
    background-color: colors.$color-grey-class-header;
    padding: 0.5rem;
    font-weight: typography.$font-weight-bold;
    flex-shrink: 0;

    &__count {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      align-items: flex-end;
      justify-content: flex-end;
      height: 100%;

      :first-child {
        align-items: center;
        flex-grow: 1;
      }
    }
  }

  &__students {
    margin: 0;
    padding: 0;
    background-color: white;
    flex-grow: 1;
  }

  &__header_locked {
    color: colors.$color-blue-primary;
    margin-right: 10px;
  }

  &__header_unlocked {
    color: colors.$color-grey-mid;
    margin-right: 7px;
  }
}
