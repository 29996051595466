.c-account-details {
  position: relative;

  &__edit {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__referal {
    background-color: white;
    padding: 16px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;

    &__left {
      padding-left: 90px;
      position: relative;
      &:before {
        content: "";
        background-image: url("./../../../images/horn.svg");
        width: 63px;
        top: 50%;
        height: 47px;
        position: absolute;
        transform: translateY(-50%);
        left: 0;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  .c-account-details-status-tooltip {
    z-index: 100;
    &__body {
      max-width: 50rem;
      padding: 1rem;
    }
  }
}
