@use "typography";

.printable-solver-page {
  display: none;
}

@media print {
  .c-solver {
    display: block;
  }
  .printable-solver-page {
    page-break-inside: avoid;
    page-break-after: always;
    display: flex;
    justify-content: left;
    width: 100%;
  }
  .printable-solver-table {
    width: 15vw;
    .table-header {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      &-class-header {
        span {
          font-weight: typography.$font-weight-medium;
        }
        &-count {
          display: flex;
          flex-direction: column;
          font-size: 12px;
          font-weight: typography.$font-weight-medium;
          align-items: flex-end;
        }
      }
      &-cell {
        border-top: 1px solid rgb(222, 226, 230);
        font-size: 10px;
        font-weight: typography.$font-weight-medium;
        padding: 0.2em;
        &:empty::after {
          content: "\00a0";
        }
      }
    }
    .table-cell {
      padding: 0.5em;
      font-size: 10px;
      line-height: 1;
      word-wrap: break-word;

      span {
        font-weight: typography.$font-weight-medium;
      }
    }
  }
  .printable-solver-table {
    width: 15%;
  }
}
