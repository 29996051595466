@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-attribute-label {
  &__text {
    font-size: 0.9rem;
    font-weight: typography.$font-weight-medium;
  }
  &__icon {
    font-size: 0.75em;
    width: 70px;
    font-weight: typography.$font-weight-medium;
  }
  &__improvement {
    color: colors.$color-blue-primary;
    position: absolute;
    font-size: 10px !important;
    font-weight: typography.$font-weight-medium;
  }
  &__boost-icon {
    width: 1.5rem;
  }
}
