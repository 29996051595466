@use "../../1-settings/colors";

.c-colour-palette {
  position: absolute;
  z-index: 1;
  border: 1px solid colors.$color-grey-mid;
  border-radius: 10px;

  &__container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &__colour {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &-icon {
      color: white;
    }
  }
}
