.c-video-modal-dialog {
  justify-content: center;
}

.c-video-modal-content {
  width: 840px;
}

.c-video-close-button {
  width: 0px;
  position: relative;
  bottom: 28px;
  left: 3px;
}

.c-video-modal-embed-responsive {
  // 16:9 aspect ratio
  padding: 56.25% 0 0 0;
  position: relative;

  > iframe {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
