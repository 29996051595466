@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-email-success {
  &__container {
    padding: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__icon-container {
    background-color: #48c744;
    border-radius: 50%;
    padding: 2rem;
    i {
      zoom: 3;
      color: white;
    }
  }
  &__email-container {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
    min-width: 458px;
    span {
      color: #000000;
      font-family: "Libre Franklin";
      font-size: 20px;
      font-weight: typography.$font-weight-medium;
      margin: 0 1rem;
    }
  }
  &__fine-print {
    padding: 2rem;
    color: rgba(52, 52, 52, 0.7);
    font-family: "Libre Franklin";
  }
  &__highlighted-fine-print {
    background-color: colors.$color-blue-lighter;
    padding: 1.25rem;
    color: rgba(52, 52, 52, 0.7);
    font-family: "Libre Franklin";
  }
}
