@use "../../1-settings/colors";

.c-toggle {
  position: relative;
  width: 250px;
  border-radius: 4px;
  overflow: hidden;
  margin-left: auto;

  &__label {
    width: 100%;
    border-radius: 4px;
    border: 1px solid colors.$color-grey-border;
    margin-bottom: 0;
    padding: 6px 0;
    background-color: white;
    position: relative;

    &:before {
      content: "";
      width: 50%;
      height: 100%;
      background-color: colors.$color-blue-mid;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(100%);
      transition: all 0.25s ease-in-out;
    }
  }

  &__checkbox {
    visibility: hidden;
    width: 0;
    height: 0;
    &:checked {
      + .c-toggle__label {
        &:before {
          transform: translateX(0%);
        }
        .c-toggle__on {
          color: white;
        }
        .c-toggle__off {
          color: colors.$color-blue-primary;
        }
      }
    }
  }

  &__on,
  &__off {
    display: inline-block;
    width: 50%;
    position: relative;
    z-index: 2;
    text-align: center;
    transition: all 0.25s ease-in-out;
    user-select: none;
    font-size: 0.9rem;
  }

  &__on {
    color: colors.$color-blue-primary;
  }

  &__off {
    color: white;
  }
}

.c-toggle--admin {
  position: relative;
  width: 250px;
  border-radius: 4px;
  overflow: hidden;
  margin-left: auto;

  .c-toggle {
    &__label {
      &:before {
        background-color: colors.$color-admin;
      }
    }
    &__on,
    &__off {
      color: black;
    }
    &__checkbox {
      &:checked {
        + .c-toggle__label {
          .c-toggle__on,
          .c-toggle__off {
            color: black;
          }
        }
      }
    }
  }
}
