@use "../../1-settings/colors";
@use "../../1-settings/typography";
@use "../pages/students";
@use "../students/students-sortable-table";

$border-color: colors.$color-grey-border;
students-sortable-table.$column-min-width: 100px;

.friendship-preferences-import-table {
  min-width: 100%;
  height: 80vh;
  overflow: scroll;
  background: #fff;

  table {
    font-size: 13px;

    thead {
      background: colors.$color-table-header;
      position: sticky;
      top: 0;
      z-index: 4;

      tr {
        &:first-child {
          th {
            font-family: "Montserrat";
            font-weight: typography.$font-weight-medium;
            padding: 1rem;
            border-top: solid 1px students.$border-color;
            background: colors.$color-table-header;
          }
        }

        th {
          font-size: 12px;
          padding: 0 8px;
          background: colors.$color-table-header;
          font-weight: typography.$font-weight-medium;

          span {
            font-weight: typography.$font-weight-medium;
          }

          &.friendship-column {
            background: colors.$color-friendships;
          }
        }
      }
    }

    tbody {
      border-top: solid 2px students.$border-color;

      tr {
        &:hover {
          td.first-name-cell {
            &:before {
              opacity: 1;
            }
          }
        }

        td {
          min-width: 220px;
          border-right: solid 0.5px rgba(0, 0, 0, 0.1);
          border-bottom: solid 0.5px rgba(0, 0, 0, 0.1);

          // Checkbox column
          &:first-child {
            min-width: auto;
          }

          &.empty-data-cell {
            font-size: 16px;
            padding: 24px;
          }
        }
      }
    }
  }

  .add-button {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    border-radius: 12px;
    background-color: colors.$color-blue-mid;
    width: 2.5rem;
    color: white;
  }

  &__even {
    background: colors.$color-grey-light;
  }

  &__odd {
    background: #fff;
  }
}
