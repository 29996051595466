@use "../../1-settings/colors";
@use "../../1-settings/typography";

$new-classes-border-right: 1px solid rgba(0, 0, 0, 0.2);

.c-classes {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;

  &__admin-banner {
    z-index: -1;
    position: absolute;
    background-color: colors.$color-admin;
    width: 100vw;
    height: 9vh;
  }

  &__table {
    &-container {
      max-height: 80vh;
      overflow: scroll;
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    }
    &-section {
      &#row-id .first {
        z-index: 4;
        border-left: 2px solid rgba(0, 0, 0, 0.2);
      }
      .sticky-table-cell {
        padding: 0;
        background-color: colors.$color-table-header;
        border-top: 2px solid rgba(0, 0, 0, 0.2);
        border-bottom: 2px solid rgba(0, 0, 0, 0.2);
        &:nth-last-child(1) {
          border-right: 2px solid rgba(0, 0, 0, 0.2);
        }
        padding-bottom: 0.5rem;
      }

      .sectionTitle {
        font-weight: typography.$font-weight-medium;
        font-family: Montserrat !important;
        font-size: 12px;
        height: 3em;
        margin: 1rem;
        overflow-x: visible;
        overflow: visible;
        color: rgba(52, 52, 52, 0.6);
      }

      .headerName {
        font-weight: typography.$font-weight-medium;
        color: rgba(52, 52, 52, 0.9);
        margin-left: 1rem;
      }

      .new-classes {
        .count {
          width: 60px;
        }

        .labelElement {
          padding-left: 0.1rem;
        }

        .grades {
          text-align: center;
          padding-left: 1em;
          padding-right: 1em;
        }
        .sectionTitle {
          max-width: 1px;
        }
      }

      .assigned-teachers {
        min-width: 10rem;
      }

      .advanced-settings {
        min-width: 12rem;
        white-space: normal;

        .classEntryCriteria {
          min-width: 30rem;
          overflow: hidden;
          word-wrap: normal;
        }
      }
    }

    &-data {
      &-row {
        .sticky-table-cell {
          background-color: inherit;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        }
        &:nth-of-type(odd) {
          background-color: white !important;
        }
        &:nth-of-type(even) {
          background-color: colors.$color-grey-table !important;
        }
      }
      &-cell {
        width: 100px;
        padding: 0.5rem 1rem !important;
        border-right: 0.5px solid rgba(0, 0, 0, 0.1);
        &:first-child {
          border-left: 2px solid rgba(0, 0, 0, 0.2);
        }
        &:nth-last-child(1) {
          border-right: 2px solid rgba(0, 0, 0, 0.2);
        }
      }
      &-count {
        text-align: center;
        border-right: none !important;
        font-weight: typography.$font-weight-medium;
        width: 60px;
      }

      &-grades {
        text-align: center;
      }

      &-teachers {
        padding-left: 0.5rem;
        border-left: 2px solid rgba(0, 0, 0, 0.2);
        min-width: 24vw;
        max-width: 24vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-classSizeSummary {
        border-left: 2px solid rgba(0, 0, 0, 0.2);
        text-align: center;
        min-width: 10rem;
      }

      &-classEntryCriteria {
        max-width: 20rem;
        min-width: 20rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }

      &-border-left-2 {
        border-left: 2px solid rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__total {
    margin: 1rem;
    color: colors.$color-total-text;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: typography.$font-weight-medium;
  }

  &__delete-icon {
    font-size: 30px;
    cursor: pointer;
    color: colors.$color-grey-mid;
  }

  &__entry-criteria {
    width: 100%;
    .heading {
      color: colors.$color-grey-text;
      text-align: left;
      font-weight: typography.$font-weight-medium;
    }
  }

  &__entry-criterion {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
