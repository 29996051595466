.c-success-modal {
  &__icon-container {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #48c744;
    border-radius: 50%;

    i {
      font-size: 22px;
      color: white;
    }
  }
}
