@use "../../1-settings/colors";
@use "../../1-settings/typography";
@use "../pages/students";
@use "../students/students-sortable-table";

$border-color: colors.$color-grey-border;
students-sortable-table.$column-min-width: 100px;

.teacher-sortable-table {
  min-width: 100%;
  height: 80vh;
  overflow: scroll;
  background: #fff;

  table {
    font-size: 13px;
    border-collapse: separate;
    border-bottom: solid 2px students.$border-color;

    thead {
      position: sticky;
      top: 0;
      z-index: 3;
      background: colors.$color-table-header;

      &.no-data {
        th {
          position: inherit !important;
          left: auto !important;
        }
      }

      tr {
        &:first-child {
          span {
            font-family: "Montserrat" !important;
            font-weight: typography.$font-weight-medium;
            color: rgba(52, 52, 52, 0.6);
          }

          th {
            font-family: "Montserrat";
            font-weight: typography.$font-weight-medium;
            color: rgba(52, 52, 52, 0.6);
            padding: 0.5rem;
            text-transform: uppercase;
            border-top: solid 2px students.$border-color;
            background: colors.$color-table-header;

            &:first-child {
              padding-left: 1rem;
            }
          }
        }

        &:last-child {
          th {
            padding-bottom: 8px;
            border-bottom: solid 2px students.$border-color;

            // First name column
            &:first-child {
              padding-left: 36px;
            }
          }
        }

        th {
          font-size: 12px;
          font-weight: typography.$font-weight-medium;
          padding: 0 8px;
          background: colors.$color-table-header;

          span {
            font-weight: typography.$font-weight-medium;
          }

          &:last-child {
            border-right: solid 2px students.$border-color;
          }

          &.first-in-group-header {
            border-left: solid 2px students.$border-color;
          }

          &.current-class-heading {
            min-width: 13rem;
          }

          &.survey-class-heading {
            min-width: 13rem;
          }

          &.close-header {
            min-width: 10rem;
          }

          &.button-header span {
            font-family: "Libre Franklin", sans-serif !important;
            font-size: 14px;
            font-weight: typography.$font-weight-bold;
            text-transform: none;
            color: colors.$color-blue-mid;
            cursor: pointer;
          }
        }
      }
    }

    tbody {
      border-top: solid 2px students.$border-color;

      tr {
        &:nth-child(even) {
          td {
            background: colors.$color-grey-light;
          }
        }

        &:hover {
          td.first-name-cell {
            &:before {
              opacity: 1;
            }
          }
        }

        td {
          padding: 8px;
          cursor: pointer;
          min-width: students-sortable-table.$column-min-width;
          border-right: solid 0.5px rgba(0, 0, 0, 0.1);
          border-bottom: solid 0.5px rgba(0, 0, 0, 0.1);
          background: #fff;

          .highlight-true {
            color: #ff0000;
          }

          &.empty-data-cell {
            font-size: 16px;
            padding: 24px;
          }

          &.first-name-cell {
            position: relative;
            min-width: 160px;
            text-overflow: clip;
            padding-left: 36px;

            &:before {
              font-size: 16px;
              position: absolute;
              content: "\F040";
              font-family: FontAwesome;
              font-style: normal;
              font-weight: typography.$font-weight-medium;
              text-decoration: inherit;
              color: #3a64b0;
              left: 14px;
              top: 50%;
              opacity: 0;
              transform: translateY(-50%);
            }
          }

          &.last-name-cell {
            min-width: 160px;
            text-overflow: clip;
          }

          &.current-class-cell {
            width: 100px;
          }

          &.survey-class-cell {
            width: 100px;
          }

          &.first-in-group-cell {
            border-left: solid 2px students.$border-color;
          }

          &.wide-cell {
            width: 300px;
            min-width: 300px;
            max-width: 300px;
          }

          &.less-wide-cell {
            width: 230px;
            min-width: 230px;
            max-width: 230px;
          }

          &.passive-cell {
            font-size: 13px;
            font-weight: typography.$font-weight-bold;
            background: colors.$color-grey-requests;

            span {
              font-size: 13px;
              font-weight: typography.$font-weight-medium;
            }
          }

          &.button-cell {
            color: colors.$color-blue-mid;
            text-wrap: nowrap;
          }

          &.end-cell {
            width: 220px;
          }
        }
      }
    }
  }
}
