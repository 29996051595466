@use "../../1-settings/colors";
@use "../../1-settings/typography";

.requests-table {
  border-top: 1px solid colors.$color-grey-border;
  max-height: 80vh;
  border-bottom: 2px solid colors.$color-grey-border;

  .requests {
    &-section {
      &#row-id .first {
        z-index: 4;
        border-left: 2px solid rgba(0, 0, 0, 0.2);
        border-right: none;
      }
      .sticky-table-cell {
        padding: 0;
        background-color: inherit;
        border-top: 2px solid rgba(0, 0, 0, 0.2);
        border-bottom: 2px solid rgba(0, 0, 0, 0.2);
        &:nth-last-child(1) {
          border-right: 2px solid rgba(0, 0, 0, 0.2);
        }
      }

      .sectionTitle {
        display: inline-block;
        font-size: 12px;
        margin: 1rem;
        overflow-x: visible;
        overflow: visible;
        color: rgba(52, 52, 52, 0.6);
        font-family: "Montserrat" !important;
        font-weight: typography.$font-weight-medium;
      }

      .headerName {
        bottom: 0;
        font-weight: typography.$font-weight-medium;
        font-size: 12px;
        padding-bottom: 0.5rem;
        margin-left: 1rem;
      }

      .pairing {
        max-width: 1px;
        border-bottom: 2px solid colors.$color-grey-border;
        background-color: colors.$color-table-header;
        .studentPair {
          white-space: pre-line;
          margin-left: 0;
          padding-left: 0;
          position: absolute;
        }
      }

      .requestDetails {
        max-width: 1px;
        border-bottom: 2px solid colors.$color-grey-border;
        background-color: colors.$color-grey-requests;
        &:last-of-type {
          border-left: 0;
          padding-left: 0;
        }
      }
    }

    &-data {
      &-row {
        .sticky-table-cell {
          background-color: inherit;
        }
        background-color: #fff;
        &:nth-of-type(even) {
          background-color: colors.$color-grey-table;
        }
      }

      &-cell {
        padding: 0.5rem;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        border-right: 0.5px solid rgba(0, 0, 0, 0.1);
        font-size: 12px;
        &:nth-last-child(1) {
          border-right: 2px solid rgba(0, 0, 0, 0.2);
        }
      }

      &-count {
        padding-left: 1rem !important;
        border-right: 0 !important;
        width: 60px;
        min-width: 60px;
      }

      &-studentDescription {
        min-width: 24vw;
        max-width: 24vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-studentName,
      &-studentTo {
        border-right: 0 !important;
        min-width: 15rem;
        max-width: 15rem;
      }

      &-studentClass,
      &-studentToClass {
        min-width: 87px;
        max-width: 110px;
        .c-class-badge {
          margin: auto;
        }
        text-align: center;
      }

      &-inputBy {
        background-color: colors.$color-grey-requests !important;
        padding: 0 1rem !important;
        border-left: 2px solid colors.$color-grey-mid;
        min-width: 150px;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-inputOn {
        background-color: colors.$color-grey-requests !important;
        padding: 0 1rem !important;
        min-width: 100px;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-requestStatus {
        background-color: colors.$color-grey-requests !important;
        min-width: 250px;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          text-overflow: clip;
          white-space: normal;
          word-break: break-all;
        }
      }
      &-studentRequestImportance {
        min-width: 6rem;
        max-width: 6rem;
        text-align: center;
      }
      &-studentPair {
        white-space: pre-line;
        min-width: 65px;
        max-width: 66px;
        margin: 1rem;
        text-align: center;
      }
    }
  }
}

.request-red {
  color: colors.$color-requests-mandatory;
  font-weight: typography.$font-weight-medium;
}

.requests-total {
  margin: 1rem;
  color: colors.$color-total-text;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: typography.$font-weight-medium;
}
