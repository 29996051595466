@use "../1-settings/colors";

.fa-unlock {
  color: #e3e3e3;
  cursor: pointer !important;
}
.fa-lock {
  color: colors.$color-blue-primary;
  cursor: pointer !important;
  width: 12px;
}
.fa-angle-left,
.fa-angle-right {
  color: #b8b8b8;
}
