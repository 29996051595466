@use "colors";

$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;

span,
p,
div,
li {
  font-family: "Libre Franklin", sans-serif !important;
  font-weight: $font-weight-light;
}
p {
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}

.text-primary {
  color: colors.$color-blue-mid !important;
  font-size: 0.9em;
  font-weight: $font-weight-medium;
}
