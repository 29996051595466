@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-student-list-item {
  position: relative;
  user-select: none;
  border-bottom: 1px solid #d7dbe0;
  list-style-type: none;
  font-size: 13px;
  padding: 0.29rem 0.5rem;
  background-color: colors.$color-white;
  &__name {
    padding-left: 0.5rem;
  }

  &--inactive {
    background-color: colors.$color-grey-mid;
  }
  &--selected {
    border: 1px solid colors.$color-blue-primary;
    border-left: 3px solid colors.$color-blue-primary;
    height: 29.25px;
    top: -2px;
    .c-student-list-item__name {
      text-decoration: underline;
      font-weight: typography.$font-weight-bold;
    }
  }
  &--dropped {
    box-shadow:
      0 0 4px 1px rgba(58, 100, 176, 0.8),
      0 0 4px 1px rgba(58, 100, 176, 0.8);
    z-index: 1000;
  }
  &--all-characteristics {
    max-width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__flags {
    &__container {
      display: flex;
      align-items: center;
      gap: 0.1rem;
    }
    &__not-met {
      font-size: 18px;

      &--friendship {
        color: colors.$color-friendships;
      }
      &--important {
        color: colors.$color-requests-important;
      }
      &--mandatory {
        color: colors.$color-requests-mandatory;
      }
      &--teacher {
        color: colors.$color-requests-teacher;
      }
      &--class-entry-criteria {
        color: colors.$color-class-entry-criteria;
      }
    }
  }
  &--current-class {
    margin-left: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 4rem;
    white-space: nowrap;

    &:hover {
      text-overflow: clip;
      white-space: normal;
    }
  }
}
