@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-button {
  &--secondary {
    padding: 6px 10px;
    border: 2px solid colors.$color-blue-mid;
    color: colors.$color-blue-mid !important;
    border-radius: 4px;
    transform: scale(1);
    transition: all 0.25s ease-in-out;
    box-shadow: 1px 6px 25px 5px rgba(0, 0, 0, 0);
    text-align: center;
    font-weight: typography.$font-weight-medium;
    height: 40px;
    width: 15.5rem;
    background-color: transparent;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      box-shadow: 1px 6px 25px 5px rgba(0, 0, 0, 0.07);
      text-decoration: none;
    }
  }
}
