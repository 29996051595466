/* ========================================================================== *\
    #BOX-SIZING
\* ========================================================================== */

/**
 * More sensible default box-sizing:
 * http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

* {
  &,
  &:after,
  &:before {
    box-sizing: inherit;
  }
}
