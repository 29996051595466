@use "../../../1-settings/colors";
@use "../../../1-settings/typography";

.c-friendships {
  border-left: 8px solid colors.$color-friendships;
  background-color: white;

  &__edit {
    background-color: colors.$color-grey-light;
  }

  &__friend-label {
    color: colors.$color-grey-text;
    font-family: "Libre Franklin";
    font-size: 14px;
    font-weight: typography.$font-weight-medium;
  }

  &__header {
    color: colors.$color-blue-primary;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: typography.$font-weight-medium;
  }

  &__friends-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
  }

  &__friend-badge {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: colors.$color-grey-table;
    border-radius: 1rem;
    color: colors.$color-grey-text;
    font-family: "Libre Franklin";
    font-size: 13px;
    padding: 0.5rem;
    margin: 0.25rem 0.5rem 0.25rem 0;
  }
}
