@use "../../1-settings/colors";
@use "../../1-settings/typography";
@use "../pages/students";

$border-color: colors.$color-grey-border;
$column-min-width: 100px;

.students-sortable-table {
  min-width: 100%;
  height: 80vh;
  overflow: scroll;
  background: #fff;

  table {
    font-size: 13px;
    border-collapse: separate;
    border-bottom: solid 2px students.$border-color;

    .sticky-column {
      position: sticky;
      z-index: 2;
    }

    thead {
      background: colors.$color-table-header;
      position: sticky;
      top: 0;
      z-index: 4;

      &.no-data {
        th {
          position: inherit !important;
          left: auto !important;
        }
      }

      tr {
        // First row in header
        &:first-child {
          // Cog icon in Characteristics header
          .fa-cog {
            font-size: 16px;
            cursor: pointer;
          }

          th {
            font-family: "Montserrat";
            font-weight: typography.$font-weight-medium;
            color: rgba(52, 52, 52, 0.6);
            padding: 1rem;
            text-transform: uppercase;
            border-top: solid 1px students.$border-color;
            background: colors.$color-table-header;

            // 'STUDENT DETAILS'
            &.student-details {
              padding-left: 1rem;
              border-right: solid 2px colors.$color-grey-border;
            }
          }
        }

        // Second row in header
        &:last-child {
          th {
            padding-bottom: 8px;
            border-bottom: solid 2px students.$border-color;
          }
        }

        th {
          font-size: 12px;
          padding: 0 8px;
          background: colors.$color-table-header;
          font-weight: typography.$font-weight-medium;

          &.first-name {
            padding-left: 36px;
            border-right: 0;
          }

          &.last-name {
            border-right: solid 2px colors.$color-grey-border;
          }

          span {
            font-weight: typography.$font-weight-medium;
          }

          &.total-column {
            text-align: center;
            background: colors.$color-requests;
          }

          &.characteristic-column {
            min-width: 9rem;
            background: colors.$color-characteristics;
          }

          &.friendship-column {
            background: colors.$color-friendships;
          }
        }
      }
    }

    tbody {
      border-top: solid 2px students.$border-color;

      tr {
        &:hover {
          td.first-name-cell {
            &:before {
              opacity: 1;
            }
          }
        }

        td {
          padding: 8px;
          cursor: pointer;
          min-width: $column-min-width;
          border-right: solid 0.5px rgba(0, 0, 0, 0.1);
          border-bottom: solid 0.5px rgba(0, 0, 0, 0.1);

          // Pencil icon next to the First name values
          .fa-pencil {
            color: colors.$color-blue-primary;
          }

          &.empty-data-cell {
            font-size: 16px;
            padding: 24px;
          }

          &.survey-cell {
            background-color: colors.$color-grey-requests;
          }

          &.index {
            min-width: 50px;
            max-width: 50px;
            text-align: right;
            border-right: none;
            padding-right: 0px;
          }

          &.first-name-cell {
            min-width: 160px;
            text-overflow: clip;
            padding-left: 36px;

            &:before {
              font-size: 16px;
              position: absolute;
              content: "\F040";
              font-family: FontAwesome;
              font-style: normal;
              font-weight: typography.$font-weight-medium;
              text-decoration: inherit;
              color: #3a64b0;
              left: 14px;
              top: 50%;
              opacity: 0;
              transform: translateY(-50%);
            }
          }

          &.last-name-cell {
            min-width: 160px;
            text-overflow: clip;
            border-right: solid 2px colors.$color-grey-border;
          }

          &.gender-cell {
            min-width: 80px;
            text-align: center;
          }

          &.current-grade-cell {
            min-width: 120px;
          }

          &.current-class-cell {
            min-width: 120px;
          }

          &.new-class-cell {
            text-align: center;
          }

          &.notes-cell {
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 8rem;
            min-width: 8rem;
            overflow: hidden;
          }

          .friend-cell {
            min-width: 220px;
            margin: auto;
          }
          .generated {
            color: colors.$color-red;
          }
        }
      }
    }
  }

  &__even {
    background: colors.$color-grey-light;
  }

  &__odd {
    background: #fff;
  }

  .add-button {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    border-radius: 12px;
    background-color: colors.$color-blue-mid;
    width: 2.5rem;
    color: white;
  }
}
