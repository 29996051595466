@use "../../1-settings/typography";

.sociogram {
  // border: 1px solid #ddd;
  font: 10px sans-serif;
  font-weight: typography.$font-weight-bold;
}

.sociogram text {
  pointer-events: none;
}

.sociogram .nodes {
  font: 9px sans-serif;
  font-weight: typography.$font-weight-bold;
}
