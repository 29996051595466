@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-badge-tooltip {
  min-width: 10rem;
  max-width: 18rem;
  box-shadow: 1px 1px 1px 1px colors.$color-grey-mid;
  &__header {
    color: black;
    width: 100%;
    font-weight: typography.$font-weight-bold;
    padding: 1rem 0.5rem 0 0.5rem;
    font-size: 13px;
  }
  &__body {
    padding: 1rem 0.5rem;
    font-size: 13px;
  }
}

.survey-tooltip {
  box-shadow: 1px 1px 1px 1px colors.$color-grey-mid;
  z-index: 10;
  width: 24rem !important;
}

.survey-learn-more {
  max-width: 5rem;
  margin: 0;
  font-size: 0.8rem !important;
  line-height: 1.2;
}
