@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-notifications {
  max-width: 400px;
  min-width: 300px;
}

.c-notifications-body {
  padding: 0px;
}

.c-notification {
  border-top: 1px solid colors.$color-grey-mid;
  padding: 1rem 1rem;
  font-size: 12px;
}

.c-notification-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  > div {
    > span {
      font-weight: typography.$font-weight-bold;
    }
  }
}

.c-notification-body {
  font-size: 12px;

  > p {
    font-size: inherit;
  }
}

.c-notification-icon {
  display: grid;
  place-items: center;
  width: max-content;
}

// Shamelessly "inspired" from https://codepen.io/nathaliesicard/pen/oRWpbJ
.c-notification-badge-text {
  position: relative;
  font-size: 60%;
  padding: 0.25em;
  border-radius: 999px;
  line-height: 0.75em;
  color: white;
  background: rgba(255, 0, 0, 0.85);
  text-align: center;
  min-width: 2em;
  font-weight: typography.$font-weight-bold;
  white-space: nowrap;
}
