.anim-appear {
  opacity: 0.01;
  transform: translateY(-200px);
  &.anim-appear-active {
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.2, 0.6, 0.22, 1);
    transform: translateY(0);
  }
}

.page-transition-enter {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  position: absolute;
  &.page-transition-enter-active {
    opacity: 1;
  }
}

.page-transition-leave {
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
  position: absolute;
  &.page-transition-leave-active {
    opacity: 0;
  }
}

@keyframes preloader {
  50% {
    transform: rotate(360deg);
  }
}
