@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-input-container {
  position: relative;

  &__input {
    width: 280px;
    font-weight: typography.$font-weight-light;

    &:focus {
      box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
    }
    &:focus + .c-input-container__icon {
      opacity: 0;
    }
    &::placeholder {
      font-weight: typography.$font-weight-light;
      color: colors.$color-grey-mid;
    }
  }

  &__icon {
    position: absolute;
    top: 1px;
    right: 1px;
    opacity: 1;
    transition: all 0.25s ease-in-out;
    .input-group-text {
      display: block;
      border: 0;
    }
    i {
      color: colors.$color-grey-mid;
    }

    &-close {
      color: colors.$color-input-icon !important;
    }
  }
}
