// NOTE: Autogenerated from https://google-webfonts-helper.herokuapp.com/fonts/libre-franklin?subsets=latin
// Please don't edit by hand, just go and regenerate it

/* libre-franklin-300 - latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("./libre-franklin-v7-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./libre-franklin-v7-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* libre-franklin-500 - latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("./libre-franklin-v7-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./libre-franklin-v7-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* libre-franklin-700 - latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./libre-franklin-v7-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./libre-franklin-v7-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
