@use "../../1-settings/colors";

.c-note {
  padding: 0.75rem 1rem;
  background-color: colors.$color-blue-lighter;
  width: 100%;
  i {
    font-size: 16px;
    color: colors.$color-blue-light;
  }
}
