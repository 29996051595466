$color-grey-light: #f6f7f8;
$color-grey-mid: #bfbfbf;
$color-grey-ed: #ededed;
$color-grey-dark: #43494e;
$color-grey-class-header: #dce1e8;
$color-grey-dropdown-header: #6c757d;
$color-grey-table: #f9f9f9;
$color-grey-border: #cccccc;
$color-grey-requests: #efefed;
$color-grey-mid-light: rgba(52, 52, 52, 0.7);
$color-blue-mid: #3a64b0;
$color-yellow-mid: #fdcc43;
$color-purple: #565294; // Not used
$color-red: #ff0000;

//=== Actual colour pallet from daniel ===
$color-grey-text: #373a3c;
$color-blue-primary: #1c3664;
$color-blue-light: #5da9e9;
$color-blue-hover: #1d2ed2;
$color-grey-default: #4a4a4a;
$color-requests: #ff7341;
$color-friendships: #2bd1b4;
$color-class-entry-criteria: #cc87f9;
$color-characteristics: #fdcc43;
$color-female: #c32659;
$color-male: #181280;
$color-non-binary: #000000;
$color-requests-mandatory: #f5341e;
$color-requests-important: #ffa700;
$color-requests-teacher: #c7c7c7;
$color-class-badge: #abc3eb;
$color-admin: #e8f0c8;
$color-read-only: #faeca7;
$color-table-header: #e3e7ed;
$color-total-text: #54585a;
$color-green-light: #7ed321;
$color-footer-text: #999999;
$color-white: #fff;
$color-black: #000;
$color-light-pink: #eb88e1;
//========================================

$color-input-icon: #495057;

$color-blue-lighter: rgba(93, 169, 233, 0.2);
$color-red-lighter: rgba(255, 0, 0, 0.2);

$color-admin_last_active_first: #2bd1b433;
$color-admin_last_active_second: #ffa70033;
$color-admin_last_active_third: #f5341e33;
$color-admin_not_activated: #cc87f933;
$color-admin_deactivated: #c7c7c733;

.color-red {
  color: $color-red !important;
}
.color-grey-light {
  color: $color-grey-light !important;
}
.color-grey-mid {
  color: $color-grey-mid !important;
}
.color-grey-mid-light {
  color: $color-grey-mid-light !important;
}
.color-grey-ed {
  color: $color-grey-ed !important;
}
.color-grey-dark {
  color: $color-grey-dark !important;
}
.color-grey-class-header {
  color: $color-grey-class-header !important;
}
.color-grey-table {
  color: $color-grey-table !important;
}
.color-grey-text {
  color: $color-grey-text !important;
}
.color-grey-border {
  color: $color-grey-border !important;
}
.color-grey-requests {
  color: $color-grey-requests !important;
}
.color-blue-mid {
  color: $color-blue-mid !important;
}
.color-purple {
  color: $color-purple !important;
}
.color-light-pink {
  color: $color-light-pink !important;
}
.color-grey-dropdown-header {
  color: $color-grey-dropdown-header !important;
}

//=== Actual colour pallet from daniel ===
.color-blue-primary {
  color: $color-blue-primary !important;
}
.color-blue-hover {
  color: $color-blue-hover !important;
}
.color-grey-default {
  color: $color-grey-default !important;
}
.color-requests {
  color: $color-requests !important;
}
.color-characteristics {
  color: $color-characteristics !important;
}
.color-friendships {
  color: $color-friendships !important;
}
.color-green-light {
  color: $color-green-light !important;
}
.color-female {
  color: $color-female !important;
}
.color-male {
  color: $color-male !important;
}
.color-non-binary {
  color: $color-non-binary !important;
}
.color-requests-mandatory {
  color: $color-requests-mandatory !important;
}
.color-requests-important {
  color: $color-requests-important !important;
}
.color-requests-teacher {
  color: $color-requests-teacher;
}
.color-input-icon {
  color: $color-input-icon !important;
}
.color-admin {
  color: $color-admin !important;
}
.color-table-header {
  color: $color-table-header !important;
}
.color-total-text {
  color: $color-total-text !important;
}
.color-footer-text {
  color: $color-footer-text !important;
}
.color-white {
  color: $color-white !important;
}
.color-black {
  color: $color-black !important;
}

.bg-requests-important {
  background-color: $color-requests-important;
}
.bg-requests-mandatory {
  background-color: $color-requests-mandatory;
}
.bg-friendships {
  background-color: $color-friendships;
}
.bg-requests-teacher {
  background-color: $color-requests-teacher;
}
.bg-class-entry-criteria {
  background-color: $color-class-entry-criteria;
}
.bg-admin {
  background-color: $color-admin !important;
}
.bg-read-only {
  background-color: $color-read-only !important;
}
.bg-table-header {
  background-color: $color-table-header !important;
}
.bg-grey-requests {
  background-color: $color-grey-requests !important;
}

.color-table-header {
  color: $color-table-header !important;
}
.color-total-text {
  color: $color-total-text !important;
}

.bg-choose-account {
  background-color: $color-requests-mandatory !important;
}
//========================================

.color-admin_last_active_first {
  background-color: $color-admin_last_active_first !important;
}
.color-admin_last_active_second {
  background-color: $color-admin_last_active_second !important;
}
.color-admin_last_active_third {
  background-color: $color-admin_last_active_third !important;
}
.color-admin_not_activated {
  background-color: $color-admin_not_activated !important;
}
.color-admin_deactivated {
  background-color: $color-admin_deactivated !important;
}
