@use "../../1-settings/colors";

.c-survey-settings {
  border-bottom: 1px solid colors.$color-blue-mid;

  &__code {
    background-color: white;
    padding: 15px;
    width: 100%;
    text-align: center;
  }
  &__left {
    width: 55%;
    padding-right: 30px;
  }
  &__right {
    width: 45%;
  }
}
