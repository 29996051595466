@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-reports-table {
  width: 100%;

  .border-right {
    // used to overwrite the bootstrap border-right
    border-right: 1px solid colors.$color-grey-border !important;
  }
  .border-left {
    // used to overwrite the bootstrap border-left
    border-left: 1px solid colors.$color-grey-border !important;
  }

  &__header {
    border: 1px solid colors.$color-grey-border;
    border-bottom: 2px solid colors.$color-grey-border;
    &__title,
    &__label {
      font-family: "Libre Franklin", sans-serif;
      font-size: 12px;
      font-weight: typography.$font-weight-medium;
      overflow: hidden;
      padding: 0 5px;
      min-width: 80px;
    }
    &__label {
      text-align: center;
      padding-bottom: 5px;
      &--performance {
        max-width: 90px;
      }
    }
  }
}
