@use "../../1-settings/colors";

.c-upload-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  width: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  &__block {
    height: 32px;
    width: 200px;
    border-radius: 4px;
    background-color: colors.$color-blue-primary;
    margin-left: 5px;
    opacity: 0.1;
    animation: pulseAnimation 2.5s infinite;
    &:nth-of-type(2) {
      animation-delay: 0.5s;
    }
    &:nth-of-type(3) {
      animation-delay: 1s;
    }
    &:nth-of-type(4) {
      animation-delay: 1.5s;
    }
    &:nth-of-type(5) {
      animation-delay: 2s;
    }
  }

  @keyframes pulseAnimation {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
}
