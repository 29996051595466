@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-chars {
  &__header {
    border-bottom: 2px solid colors.$color-grey-mid;

    &-item {
      font-size: 12px;
      font-weight: typography.$font-weight-bold;
    }
  }

  &__body {
    position: relative;
    margin-top: 20px;
    border-bottom: 1px solid colors.$color-grey-mid;
    &-item {
      font-size: 12px;
    }
  }

  &__icon {
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: colors.$color-grey-mid;
    color: white;
    border-radius: 50%;
  }

  &__arrow-icon {
    color: colors.$color-grey-mid;
    margin-left: 0.5rem;
  }

  &__delete-icon {
    font-size: 20px;
    cursor: pointer;
    color: colors.$color-grey-mid;
    position: absolute;
    right: -10px;
    top: 0;
  }

  &__input {
    width: 100%;
    background-color: white;
    border: 1px solid colors.$color-grey-mid;
    border-radius: 4px;
    padding: 8px 20px;
    margin-bottom: 3px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    &:disabled {
      font-weight: typography.$font-weight-bold;
      color: rgba(52, 52, 52, 0.75);
      background-color: colors.$color-grey-light;
      border: none;
    }
  }

  &__dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 32px;
    color: white;
    border-radius: 50%;
    margin-right: 20px;
    transform: scale(1);
    transition: all 0.25s ease-in-out;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }

  &__response-color {
    margin: 0 3px 3px 0;
    transform: scale(1);
    transition: all 0.25s ease-in-out;
    &::before {
      content: "v";
      opacity: 0;
      height: 100%;
      width: 3px;
    }
  }

  &__label {
    position: relative;
    margin-bottom: 3px;

    .c-chars__input {
      margin-bottom: 0;
      margin-left: 10px;
    }

    &:first-child:before {
      opacity: 1;
    }
    &:last-child:before {
      background-color: colors.$color-grey-mid;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0%;
      top: 0;
      height: 100%;
      width: 6px;
      background-color: colors.$color-blue-mid;
    }
  }
}
