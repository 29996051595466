@use "../../1-settings/typography";

.c-footer {
  font-family: "Libre Franklin";
  font-size: 14px;
  font-weight: typography.$font-weight-medium;

  &__logo {
    max-width: 100px;
    filter: grayscale(100%);
  }
  &__need-help {
    font-family: "Montserrat" !important;
    font-size: 20px;
    font-weight: typography.$font-weight-medium;
    &__icon {
      &:after {
        content: "\f256";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: typography.$font-weight-medium;
      }
    }
  }
  &__phone,
  &__email {
    font-family: "Montserrat" !important;
    font-size: 14px;
    font-weight: typography.$font-weight-medium;
  }
  &__phone {
    font-family: "Montserrat" !important;
    font-size: 14px;
    font-weight: typography.$font-weight-medium;
    &__number {
      font-family: "Montserrat" !important;
      font-size: 14px;
      font-weight: typography.$font-weight-medium;
    }
  }
}
