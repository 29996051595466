@use "../../1-settings/colors";

.modal-header,
.modal-body,
.modal-footer {
  border: none;
  background-color: colors.$color-grey-light;
}

.modal-header {
  padding: 30px 30px 0px;
  border-radius: 10px 10px 0px 0px;
}

.modal-body {
  .title-border-underline {
    border-bottom: 2px solid colors.$color-grey-border;
  }
  .school-border-underline {
    border-bottom: 1px solid colors.$color-grey-border;
  }
}

.modal-footer {
  border-radius: 0px 0px 10px 10px;
}
