@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-search-input {
  position: relative;

  &__input {
    width: 280px;
    font-weight: typography.$font-weight-light;
    padding: 6px 20px 6px 12px;
    &:focus {
      box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
    }
    &::placeholder {
      font-weight: typography.$font-weight-light;
      color: colors.$color-grey-mid;
    }
  }

  &__icon {
    position: absolute;
    top: 7px;
    right: 8px;
    background-color: transparent;
  }
}
