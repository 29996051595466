@use "colors";
@use "typography";

@media print {
  // DashBaord Page
  .c-dashboard {
    margin-top: 100px;
  }
  //Student Page
  .c-students-page {
    margin-top: 175px;
    .current-grade-input {
      position: absolute !important;
      left: 0px;
      height: 20px;
      label {
        display: none;
      }
      select {
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        &::-ms-expand {
          display: none;
        }
        font-weight: typography.$font-weight-medium;
        text-decoration: underline;
      }
    }
    .c-students-table {
      margin-top: 30px;
    }
  }
  //Teachers Page
  .c-teachers {
    margin-top: 175px !important;
    .c-teachers__table-container {
      .sticky-table {
        overflow: visible !important;
        .sticky-table-table {
          overflow: visible !important;
          border: 1px solid colors.$color-total-text;
        }
        .sticky-table-y-wrapper {
          overflow: visible !important;
        }
      }
    }
    .c-teachers-invite {
      display: none;
    }
  }
  //Requests Page
  .c-requests {
    margin-top: 175px !important;
    .current-grade-input {
      position: absolute !important;
      left: 0px;
      height: 25px;
      label {
        display: none;
      }
      select {
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        &::-ms-expand {
          display: none;
        }
        font-weight: typography.$font-weight-medium;
        font-size: 18px;
        text-decoration: underline;
      }
    }
    .requests-table {
      margin-top: 30px !important;
      .sticky-table {
        overflow: visible !important;
        border: 1px solid colors.$color-total-text;
        .sticky-table-table {
          border: 1px solid colors.$color-total-text;
        }
        .sticky-table-y-wrapper {
          overflow: visible !important;
        }
      }
    }
  }
  .c-classes {
    margin-top: 200px !important;
    .sticky-table {
      overflow: visible !important;
      border: 1px solid colors.$color-total-text;
      .sticky-table-table {
        border: 1px solid colors.$color-total-text;
      }
      .sticky-table-y-wrapper {
        overflow: visible !important;
        .sticky-table-x-wrapper {
          display: block;
        }
      }
    }
  }
  .c-reports-table {
    &__header__label {
      min-width: 50px !important;
      &--performance {
        min-width: 50px !important;
      }
    }
    &-body__row__data {
      min-width: 50px !important;
    }
  }
}

@page {
  size: A4 landscape;
}
