@use "../../../1-settings/colors";
@use "../../../1-settings/typography";

.c-student-modal-requests {
  border-left: 8px solid #fd7c50;
  background-color: white;

  &__edit {
    background-color: colors.$color-grey-light;
  }

  &__add {
    background-color: colors.$color-grey-light;
  }

  &__header {
    color: colors.$color-blue-primary;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: typography.$font-weight-medium;

    &-container {
      display: flex;
      justify-content: space-between;
      height: 2rem;
    }
  }
  &__no-requests {
    color: colors.$color-grey-default;
    font-family: "Libre Franklin";
    font-size: 14px;
  }
  &__table {
    &-header {
      color: rgba(52, 52, 52, 0.9);
      font-family: "Libre Franklin";
      font-size: 12px;
      font-weight: typography.$font-weight-bold;
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    }
    &-row {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding: 0.5rem 0;
      font-size: 13px;
    }
  }
}
