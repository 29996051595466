.c-grade-label-mapping {
  &__gradeLabel {
    box-sizing: border-box;
    border: 1.5px solid #3b2584;
    border-radius: 12px;
  }

  &__records {
    font-size: 11px;
  }
}
