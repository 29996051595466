@use "../../1-settings/typography";

.c-selected-student-details {
  &__student-overview {
    &__icon {
      margin-right: 10px;
    }
    &__name {
      font-size: 14px;
      padding-right: 5px;
      font-weight: typography.$font-weight-medium;
    }
    &__comments {
      &-collapsed {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &-expanded {
        white-space: normal;
        word-break: break-word;
      }
    }
  }
  &__characteristics {
    padding-left: 28px;
  }
}
