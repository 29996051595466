@use "../../1-settings/colors";

.c-characteristic-tooltip {
  min-width: 10rem;
  background-color: white;
  box-shadow: 1px 1px 1px 1px colors.$color-grey-mid;
  z-index: 1;
  &__header {
    font-size: 14px;
    padding: 1rem;
    width: 100%;
    background-color: colors.$color-grey-light;
    border-bottom: 1px solid colors.$color-grey-mid;
  }
  &__body {
    font-size: 14px;
    padding: 0 1rem 1rem 1rem;
  }
  &__description {
    max-width: 50rem;
  }
}
