.u-characteristic-circle {
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
}

.u-success-icon {
  color: white;
  font-size: 40px;
  width: 100px;
  height: 100px;
  background-color: #48c744;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.u-wrap-normal {
  white-space: normal;
}

.u-white-space-pre-wrap {
  white-space: pre-wrap;
}

.u-row-fix {
  margin: 0 -15px;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
