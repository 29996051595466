.c-linked-account-table {
  border: none;

  tr,
  th,
  td,
  thead th,
  tbody {
    border: none;
  }

  thead tr {
    font-family: "Montserrat";
  }
}
