@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-solver-teachers {
  background-color: #f5f6fa;
  display: flex;
  flex-shrink: 0;
  position: relative;

  &--close {
    height: 58px;
  }

  &__badge-left {
    width: 40px;
    display: flex;
    justify-content: left;
  }

  &__arrow {
    font-size: 22px;
    font-weight: typography.$font-weight-bold;
    color: #b8b8b8;
    position: absolute;
    bottom: 1px;
    right: 11px;
    height: 1.4rem;
  }

  &__arrow_space {
    position: relative;
    width: 220px;
    height: 0.8rem;
    top: 3px;
  }

  &_outer {
    border-bottom: 2px solid #d7dbe0;
  }

  &__pencil {
    visibility: hidden;
    margin-top: 0.35rem !important;
  }
  &--selected {
    border: 1px solid colors.$color-blue-primary;
    border-left: 3px solid colors.$color-blue-primary;
  }
  &__teacher {
    color: #6e6e6e;
    padding: 0.29rem 0;
    height: 29px;
    width: 181px;
    cursor: pointer;
    font-size: 13px;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    &--selected {
      color: colors.$color-blue-primary;
      font-weight: typography.$font-weight-bold;
    }
  }
}

.c-solver-teachers:hover .c-solver-teachers__pencil {
  font-size: 16px;
  color: colors.$color-blue-mid;
  visibility: visible;
}
