@use "../1-settings/colors";
@use "../1-settings/typography";

// These shoudl not be here, move i need to move them to the actual bootstrap SASS
// rather then override, will leave for now

.form-control-label {
  color: colors.$color-grey-text;
  font-family: "Libre Franklin";
  font-size: 12px;
  font-weight: typography.$font-weight-medium;
  margin-bottom: 0.2rem;
}

.modal-dialog {
  max-width: 840px !important;
}

.modal {
  .modal-body {
    background-color: colors.$color-grey-light;
    border-radius: 4px;
  }
  .modal-content {
    border-width: 0;
    border-radius: 4px;
  }
}

.dropdown-item {
  color: colors.$color-blue-primary;
  font-size: 14px;
  font-weight: typography.$font-weight-light;
  cursor: pointer;
  &:hover {
    background-color: colors.$color-blue-lighter !important;
  }
  a {
    color: colors.$color-blue-primary;
    text-decoration: none !important;
  }
}

.form-control-label {
  font-size: 0.8rem !important;
  font-weight: typography.$font-weight-medium !important;
}
.form-control {
  border-color: colors.$color-grey-border !important;
  font-weight: typography.$font-weight-light;

  &::placeholder {
    color: colors.$color-grey-mid;
    font-weight: typography.$font-weight-light;
    font-size: 0.9rem;
  }
}

.btn-primary {
  background-color: colors.$color-blue-mid;
  border-color: colors.$color-blue-mid;
  &:disabled {
    background-color: colors.$color-grey-mid;
    border: none;
  }
}

.btn-secondary {
  font-weight: typography.$font-weight-medium !important;
  background-color: white;
  color: colors.$color-blue-mid;
  border: 2px solid colors.$color-blue-mid;
  &:hover {
    background-color: initial;
    border-color: colors.$color-blue-mid;
    color: colors.$color-blue-mid;
  }
  &:disabled {
    background-color: colors.$color-grey-mid;
    border: none;
    &:hover {
      color: colors.$color-white;
    }
  }
}

.btn-light {
  font-weight: typography.$font-weight-bold !important;
  background-color: colors.$color-grey-light;
  color: colors.$color-grey-dark;
  border: 2px solid colors.$color-grey-light;
  &:hover {
    background-color: initial;
    border-color: colors.$color-grey-light;
    color: colors.$color-grey-dark;
  }
}

.btn-mid {
  font-weight: typography.$font-weight-medium !important;
  color: colors.$color-grey-text;
  border: 1px solid colors.$color-grey-mid;
  &:hover {
    background-color: initial;
    border-color: colors.$color-blue-mid;
    color: colors.$color-blue-mid;
  }
  &.active {
    color: colors.$color-white !important;
    background-color: colors.$color-blue-mid !important;
  }
  &:disabled {
    background-color: colors.$color-grey-mid;
    border: none;
    &:hover {
      color: colors.$color-white;
    }
  }
}

.btn-mid-150-width {
  font-weight: typography.$font-weight-light !important;
  color: colors.$color-grey-text;
  border: 1px solid colors.$color-grey-mid;
  padding: 6px 10px !important;
  &:hover {
    background-color: initial;
    border-color: colors.$color-blue-mid;
    color: colors.$color-blue-mid;
  }
  &.active {
    color: colors.$color-white !important;
    background-color: colors.$color-blue-mid !important;
  }
  &:disabled {
    background-color: colors.$color-grey-mid;
    border: none;
    &:hover {
      color: colors.$color-white;
    }
  }
  width: 150px;
}

.btn {
  padding: 6px 30px;
  font-weight: typography.$font-weight-light;
  font-size: 0.9rem;
  &-text {
    background-color: initial;
    border-width: 0;
    color: colors.$color-blue-mid;
    font-weight: typography.$font-weight-medium;

    &:hover {
      border-color: 0;
      background-color: initial;
      color: colors.$color-blue-mid;
    }
  }
}

.btn-200-width {
  display: flex;
  justify-content: center;
  border-radius: 0.25rem;
  padding: 6px 0px;
  width: 200px;
  font-weight: typography.$font-weight-light;
  font-size: 0.9rem;
  &-text {
    background-color: initial;
    border-width: 0;
    color: colors.$color-blue-mid;
    font-weight: typography.$font-weight-medium;

    &:hover {
      border-color: 0;
      background-color: initial;
      color: colors.$color-blue-mid;
    }
  }
}

.btn-120-width {
  display: flex;
  justify-content: center;
  align-items: center;
  // justify-items: center;
  width: 120px;
}
