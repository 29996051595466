@use "../1-settings/colors";
@use "../1-settings/typography";

.u-modal {
  &__header {
    h6 {
      color: colors.$color-grey-text;
      font-family: Montserrat;
      font-size: 20px;
      font-weight: typography.$font-weight-medium;
      margin-bottom: 0;
    }
  }

  &__close-icon {
    color: colors.$color-input-icon;
    zoom: 1.5;
    cursor: pointer;
  }

  &__w-30rem {
    width: 30rem;
  }

  &__w-35rem {
    width: 35rem;
  }

  &__w-40rem {
    width: 40rem;
  }

  &__w-45rem {
    width: 45rem;
  }

  &__h-24rem {
    height: 24rem;
  }

  &__h-20rem {
    height: 14rem;
  }

  &__h-14rem {
    height: 14rem;
  }

  &__note {
    padding: 0.75rem 1rem;
    border-top: 3px solid colors.$color-blue-light;
    background-color: rgba(93, 169, 233, 0.2);
    color: colors.$color-grey-text;
    font-family: "Libre Franklin";
    font-size: 13px;
    font-weight: typography.$font-weight-medium;
    i {
      font-size: 16px;
      color: colors.$color-blue-light;
    }
  }
}
