@use "sass:color";
@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-link {
  transition: all 0.3s ease-in-out;
  color: colors.$color-blue-mid !important;
  font-weight: typography.$font-weight-medium;
  &:hover {
    color: color.adjust(colors.$color-blue-mid, $lightness: 10%) !important;
  }
}
