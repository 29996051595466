@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-dashboard {
  &__timeline {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__admin-banner {
    width: 100vw;
    height: 1rem;
    background-color: colors.$color-admin;
  }

  a[disabled] {
    pointer-events: none;
    color: colors.$color-grey-mid;
    i {
      color: colors.$color-grey-mid;
    }
  }

  &__circle {
    border-radius: 50%;
    background-color: colors.$color-green-light;
    color: white;
    position: relative;
    text-align: center;
    border: 3px solid colors.$color-green-light;
    min-width: 44px;
    min-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-mini {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: colors.$color-green-light;
      margin: 0 5px;
      padding: 6px;
      &--alt {
        background-color: colors.$color-grey-mid;
      }
    }
    &--alt {
      background-color: white;
      color: colors.$color-blue-primary;
      border: 3px solid colors.$color-blue-primary;
    }
  }

  &__bar {
    width: 100%;
    height: 4px;
    background-color: colors.$color-green-light;
    position: relative;
  }

  &__bar--alt {
    background-color: colors.$color-grey-mid;
  }

  &__banner {
    background-color: colors.$color-grey-light;
    margin-top: 20px;
    background-image: url("./../../../images/ClassSolverLogo.svg");
    background-repeat: no-repeat;
    background-position: right center;

    .c-dashboard__circle i {
      color: white;
    }
    p {
      color: colors.$color-grey-dark;
    }
    a,
    i {
      color: colors.$color-blue-mid;
    }
  }

  &__stats {
    background-color: #fff;
    padding-bottom: 40px;

    &-raised {
      position: relative;
      background: linear-gradient(
        90deg,
        #37558a 0%,
        colors.$color-blue-primary 100%
      );
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      h3 {
        color: white;
      }
      .c-dashboard__stats-text {
        color: white;
      }
    }
    &-heading {
      color: white;
      background: linear-gradient(
        90deg,
        #37558a 0%,
        colors.$color-blue-primary 100%
      );
      position: absolute;
      left: 0;
      width: 100%;
      padding: 40px 25px;
      transform: translateY(-100%);
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    &-container {
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 25px;
      &-border {
        border-right: 1px solid colors.$color-blue-mid;
      }
    }
    &-text {
      font-size: 40px;
      font-weight: typography.$font-weight-light;
      &-small {
        font-size: 14px;
      }
    }
  }
}
