@use "sass:color";
@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-friendship-radio {
  display: flex;
  align-items: center;

  &__option {
    border-left: 1px solid colors.$color-grey-mid;
    border-top: 1px solid colors.$color-grey-mid;
    border-bottom: 1px solid colors.$color-grey-mid;
    font-size: 12px;
    background-color: white;
    user-select: none;
    color: colors.$color-blue-mid;
    font-weight: typography.$font-weight-medium;
    transition: all 0.25s ease-in-out;

    &:hover {
      background-color: color.adjust(colors.$color-blue-mid, $lightness: 50%);
    }

    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
      border: 1px solid colors.$color-grey-mid;
      border-radius: 0px 4px 4px 0px;
    }
  }

  &__input {
    visibility: hidden;
    width: 0;
    height: 0;
    &:checked {
      + .c-friendship-radio__label {
        background-color: colors.$color-blue-mid;
        color: white;
      }
    }
  }
  &__label {
    padding: 10px 24px;
    margin-bottom: 0;
  }
}
