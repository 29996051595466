@use "../1-settings/colors";

::placeholder {
  color: colors.$color-grey-border;
}

body {
  /* This is temporary will move to base bootstrap file, with no important */
  background-color: colors.$color-grey-light !important;
}

a:hover,
label:hover {
  cursor: pointer;
}
