@use "../../1-settings/colors";

.c-admin {
  &__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }
  &__sub-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__list-container {
    padding-left: 0;
  }
  &__icon {
    width: 50px;
    height: 50px;
    background-color: colors.$color-blue-primary;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 24px;
    &--light {
      background-color: rgba(colors.$color-blue-primary, 0.4);
    }
  }
}
