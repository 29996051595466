@use "../../../1-settings/colors";
@use "../../../1-settings/typography";

.c-student-modal-characteristics {
  border-left: 8px solid colors.$color-characteristics;
  background-color: white;

  &__edit {
    background-color: colors.$color-grey-light;
    &-tooltip {
      opacity: 1 !important;
      box-shadow: 1px 1px 1px 1px colors.$color-grey-mid;
    }
    .icon {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &__header {
    color: colors.$color-blue-primary;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: typography.$font-weight-medium;

    &-container {
      display: flex;
      justify-content: space-between;
      height: 2rem;
    }
  }
  &__label {
    display: inline-block;
    padding: 0.25rem 1rem;
    margin-bottom: 0.5rem;
    color: colors.$color-grey-text;
    font-family: "Libre Franklin";
    font-size: 14px;
    font-weight: typography.$font-weight-medium;
    max-width: 20rem;
  }
  &__response {
    color: colors.$color-grey-text;
    font-size: 14px;
    i {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
