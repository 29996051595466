@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-teachers-invite {
  min-width: 500px;
  height: 50vh;
  background-color: colors.$color-grey-requests;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  &__heading {
    margin: 1rem 2rem;
    color: rgba(52, 52, 52, 0.6);
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: typography.$font-weight-medium;
  }
}

.c-text-small {
  font-size: 0.75em;
  font-weight: typography.$font-weight-light;
  width: 190px;
  text-align: center;
}
