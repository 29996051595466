@use "../1-settings/typography";

h1 {
  font-size: 24px;
  font-weight: typography.$font-weight-bold;
}
h2 {
  font-size: 20px;
  font-weight: typography.$font-weight-medium;
}
h3 {
  font-size: 14px;
  font-weight: typography.$font-weight-medium;
}
h4 {
  font-size: 12px;
  font-weight: typography.$font-weight-medium;
}
