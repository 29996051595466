@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-teachers-invite-modal {
  &__message {
    height: 8rem;
  }
  &__text {
    color: colors.$color-blue-primary;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: typography.$font-weight-medium;
  }
  &__restricted-survey-tooltip {
    z-index: 100;
    min-width: 10rem;
    max-width: 50%;
    box-shadow: 1px 1px 1px 1px colors.$color-grey-mid;
  }
}
