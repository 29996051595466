@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-modal-expired-note {
  padding: 0.75rem 1rem;
  border-top: 3px solid colors.$color-red;
  background-color: colors.$color-red-lighter;
  width: 100%;
  &--success {
    border-color: colors.$color-green-light;
    background-color: rgba(colors.$color-green-light, 0.4);
  }
  & > div {
    color: colors.$color-grey-text;
    font-family: "Libre Franklin";
    font-size: 13px;
    font-weight: typography.$font-weight-medium;
  }
  i {
    font-size: 16px;
    color: colors.$color-blue-light;
  }
  &--request-admin {
    background-color: colors.$color-admin;
    border-color: #dbea97;
    color: colors.$color-grey-text;
    font-family: "Libre Franklin";
    font-size: 13px;
    font-weight: typography.$font-weight-medium;
    i {
      color: colors.$color-blue-primary;
    }
  }
  &--sub-note {
    padding: 0.75rem 1rem;
    border-top: 2px solid colors.$color-blue-light;
    border-bottom: 1px solid colors.$color-blue-light;
    background-color: colors.$color-blue-lighter;
    width: 100%;
    color: colors.$color-grey-text;
    font-family: "Libre Franklin";
    font-size: 13px;
    font-weight: typography.$font-weight-medium;
  }
}
