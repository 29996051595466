@use "../../1-settings/colors";
@use "../../1-settings/typography";

$survey-bg-color: colors.$color-grey-requests;
$detail-bg-color: colors.$color-table-header;

.c-teacher-modal-details {
  &__teacher-email-generated {
    border-color: colors.$color-light-pink !important;
  }
}
.c-teachers {
  &__total {
    color: colors.$color-total-text;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: typography.$font-weight-medium;
  }

  &__close-survey {
    color: colors.$color-blue-mid;
    font-size: 14px;
    font-weight: typography.$font-weight-bold;
    cursor: pointer;
  }

  &__new {
    background-color: #80debc !important;
  }

  &__table {
    &-container {
      height: 80vh;
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    }

    &-section {
      &#row-id .first {
        z-index: 4;
        border-left: 2px solid rgba(0, 0, 0, 0.2);
        border-right: none;
      }
      .sticky-table-cell {
        padding: 0;
        background-color: inherit;
        border-top: 2px solid rgba(0, 0, 0, 0.2);
        border-bottom: 2px solid rgba(0, 0, 0, 0.2);
        &:nth-last-child(1) {
          border-right: 2px solid rgba(0, 0, 0, 0.2);
        }
      }
      .sectionTitle {
        font-weight: typography.$font-weight-medium;
        font-family: "Montserrat" !important;
        font-size: 12px;
        margin: 1rem;
        overflow-x: visible;
        overflow: visible;
        color: rgba(52, 52, 52, 0.6);
        height: 3em;
      }
      .headerName {
        color: rgba(52, 52, 52, 0.9);
        font-family: "Libre Franklin";
        font-size: 12px;
        font-weight: typography.$font-weight-medium;
      }
      .current-classes,
      .teacher-details {
        background-color: $detail-bg-color;
      }
      .survey {
        background-color: $survey-bg-color;
        .allocated {
          padding-left: 0.5rem;
          min-width: 300px;
          max-width: 300px;
        }
        .status {
          padding-left: 0.5rem;
          min-width: 8rem;
          max-width: 8rem;
        }
        .resend,
        .date {
          min-width: 100px;
        }
      }
      .current-classes {
        max-width: 1px;
        .classOne,
        .classTwo {
          min-width: 100px;
          max-width: 100px;
          padding-left: 0.5rem;
        }
      }
      .teacher-details {
        max-width: 1px;
        .firstName,
        .lastName {
          padding-left: 0.5rem;
          min-width: 120;
          max-width: 120;
        }
        .email {
          padding-left: 0.5rem;
          min-width: 300px;
          max-width: 300px;
        }
      }
    }

    &-data {
      &-row {
        .sticky-table-cell {
          background-color: inherit;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        }
        &:nth-of-type(odd) {
          background-color: white !important;
        }
        &:nth-of-type(even) {
          background-color: colors.$color-grey-table !important;
        }
      }
      &-cell {
        min-width: 6rem;
        color: colors.$color-grey-text;
        font-family: "Libre Franklin";
        font-size: 13px;
        padding: 0.5rem 0;
        border-right: 0.5px solid rgba(0, 0, 0, 0.1);
        .sticky-table-cell {
          border-right: 0.5px solid rgba(0, 0, 0, 0.1);
        }
        &:nth-last-child(1) {
          border-right: 2px solid rgba(0, 0, 0, 0.2);
        }
        &.highlighted {
          color: colors.$color-red;
        }
      }
      &-count {
        text-align: center;
        min-width: 60px;
      }
      &-firstName,
      &-lastName {
        min-width: 120px;
        max-width: 120px;
        padding-left: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-email {
        padding: 0 0.5rem;
        min-width: 300px;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-classOne {
        width: 100px;
        text-align: center;
        border-left: 2px solid rgba(0, 0, 0, 0.2);
      }
      &-classTwo {
        width: 100px;
        margin: auto;
      }
      &-status {
        min-width: 8rem;
        max-width: 8rem;
        padding-left: 0.5rem;
        > span {
          font-weight: typography.$font-weight-bold;
        }
      }
      &-allocated,
      &-status,
      &-date,
      &-resend {
        &.sticky-table-cell {
          background-color: $survey-bg-color;
        }
      }
      &-date,
      &-status {
        border-right: none;
      }
      &-allocated {
        padding: 0 0.5rem;
        border-left: 2px solid rgba(0, 0, 0, 0.2);
        min-width: 300px;
        max-width: 300px;
      }
      &-resend {
        > span {
          color: colors.$color-blue-mid;
          cursor: pointer;
          font-weight: typography.$font-weight-bold;
        }
      }
    }
  }
}
