@use "../../1-settings/colors";
@use "../../1-settings/typography";

.c-class-badge {
  background-color: colors.$color-class-badge;
  height: 16px;
  width: 90px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  &__label {
    color: colors.$color-grey-text;
    font-size: 11px !important;
    font-weight: typography.$font-weight-medium;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  &--new {
    background-color: #80debc;
  }
  &--disabled {
    // Slightly darker grey
    background-color: colors.$color-grey-ed;
    span {
      color: unset;
    }
  }
}

.c-class-badge-tooltip {
  color: colors.$color-grey-text;
  background-color: white;
  padding: 0.25rem;
  opacity: 1 !important;
  box-shadow: 1px 1px 1px 1px colors.$color-grey-mid;
}

.c-class-badge-disabled {
}
