input {
  .form-check-input {
    margin-top: 0.35rem;
  }
  .active {
    border-color: rgba(255, 255, 255, 1) !important;
  }
}

label {
  user-select: none;
}

.c-input__multiselect {
  width: 100%;
}
