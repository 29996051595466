@use "../1-settings/colors";
@use "../1-settings/typography";

.u-bold {
  font-weight: typography.$font-weight-bold !important;
}
.u-grey-dark {
  color: colors.$color-grey-dark !important;
}
.u-grey-mid {
  color: colors.$color-grey-mid !important;
}
.u-blue-primary {
  color: colors.$color-blue-primary !important;
}
.u-blue-mid {
  color: colors.$color-blue-mid !important;
}

.u-yellow-mid {
  color: colors.$color-yellow-mid !important;
}

.u-bg-grey-light {
  background-color: colors.$color-grey-light;
}
.u-underline {
  text-decoration: underline;
}
.color-inherit {
  color: inherit;
}
.u-mw-750 {
  max-width: 750px !important;
}

.u-text-10 {
  font-size: 10px;
}
.u-text-12 {
  font-size: 12px;
}
.u-text-18 {
  font-size: 18px;
}
.u-text-20 {
  font-size: 20px;
}
.u-content-border-bottom {
  border-bottom: 1px solid colors.$color-blue-mid;
}

.u-separator-border-top {
  border-top: 2px solid colors.$color-grey-mid;
}

.u-heading-separator-border-bottom {
  border-bottom: 3px solid colors.$color-grey-mid;
}

.u-separator-border-bottom {
  border-bottom: 1.5px solid colors.$color-grey-mid;
}

.u-w-55 {
  width: 55%;
}
.u-w-45 {
  width: 45%;
}
.o-component {
  margin-top: 90px;
  margin-bottom: 90px;
}
.u-opacity-0 {
  opacity: 0;
}
.u-opacity-50 {
  opacity: 0.5;
}
.u-opacity-100 {
  opacity: 1;
}
.pb-6 {
  padding-bottom: 4rem;
}
.pb-7 {
  padding-bottom: 5rem;
}
.pt-6 {
  padding-top: 4rem;
}
.pt-7 {
  padding-top: 5rem;
}
.mb-6 {
  margin-bottom: 4rem;
}
.mb-7 {
  margin-bottom: 5rem;
}
.mt-6 {
  margin-top: 4rem;
}
.mt-7 {
  margin-top: 5rem;
}
.m-a {
  margin: auto;
}
.mr-a {
  margin-right: auto;
}
.px-2rem {
  padding-left: 2rem;
  padding-right: 2rem;
}

.flex-grow-1 {
  flex-grow: 1;
}

.u-font-weight-medium {
  font-weight: typography.$font-weight-medium;
}

.overflow-x-auto {
  overflow-x: auto;
}
.ml-A {
  margin-left: auto !important;
}
.border-right {
  border-right: 1px solid #ccc;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.icon-circle {
  background-color: colors.$color-blue-primary;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  i {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    color: white;
  }
}

.u-caution-icon {
  color: #f5a623;
  font-size: 24px;
}

.u-caution-text {
  color: colors.$color-blue-primary;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: typography.$font-weight-medium;
}

.class-badge {
  padding: 12px;
  background-color: colors.$color-blue-primary;
  border-radius: 50%;
  color: white;
}

.u-cross {
  position: relative;
  &::before {
    content: "";
    background-color: white;
    width: 2px;
    height: 16px;
    position: absolute;
    left: 7px;
  }
  &:after {
    content: "";
    background-color: white;
    height: 2px;
    width: 16px;
    position: absolute;
    top: 7px;
  }
}
.u-nav-border {
  border-left: 1px solid colors.$color-grey-mid;
}

.w-40 {
  width: 40%;
}
.w-15rem {
  width: 15rem;
}

.w-40rem {
  width: 40rem;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-14 {
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-wait * {
  cursor: wait !important;
}
.u-p-fix {
  margin: 0 1px;
}
.u-settings-link {
  font-weight: typography.$font-weight-medium !important;
}

.u-total-text {
  color: colors.$color-total-text;
  font-family: "Montserrat" !important;
  font-size: 12px;
  font-weight: typography.$font-weight-medium;
}

.u-data-row {
  &:hover {
    cursor: pointer;
    .u-table-edit-icon:after {
      opacity: 1;
    }
  }
}

.u-table-edit-icon {
  position: relative;
  &:after {
    position: absolute;
    content: "\F040";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: typography.$font-weight-medium;
    text-decoration: inherit;
    color: colors.$color-blue-mid;
    right: 5px;
    top: 50%;
    font-size: 16px;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }
}

.show > .btn-secondary.dropdown-toggle {
  background-color: colors.$color-blue-mid;
  border-color: colors.$color-blue-mid;
}

.u-layout-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.u-layout-component-grow {
  flex: 1;
}

.text-success-or-danger {
  // text-align: center !important;
  padding-top: 6px;
}
