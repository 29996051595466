@use "../../1-settings/colors";
@use "../../1-settings/typography";

.shared-solution-list {
  &__header {
    border-bottom: 2px solid colors.$color-grey-border;

    &__label {
      color: rgba(52, 52, 52, 0.9);
      font-family: "Libre Franklin";
      font-size: 12px;
      font-weight: typography.$font-weight-bold;
    }
  }
  &__item {
    &:hover {
      background-color: colors.$color-blue-lighter;
      .shared-solution-list__item__label__pencil {
        opacity: 1;
      }
    }
    &__icon {
      color: rgba(0, 0, 0, 0.38);
      font-size: 14px;
      cursor: pointer;
      &--times {
        font-size: 19.2px;
      }
    }
    &__label {
      color: colors.$color-grey-text;
      font-family: "Libre Franklin";
      font-size: 14px;
      &__pencil {
        color: colors.$color-blue-mid;
        font-size: 20px;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}

.shared-solutions-menu {
  &__heading {
    color: colors.$color-grey-text;
    text-align: left;
    font-weight: typography.$font-weight-bold;
  }
}

.shared-solution-badge {
  background-color: colors.$color-class-badge;
  //  height: 16px;
  margin: 0.25rem;
  width: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  &__label {
    color: colors.$color-grey-text;
    font-size: 12px;
    font-weight: typography.$font-weight-bold;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }
}

.shared-solution-modal {
  // This is the only way to override the
  // hacked in value for `.modal-dialog` in
  // _boot-overrides.scss
  max-width: 1390px !important;
}

.shared-solution-table {
  width: 100%;
  font-size: 14px;

  thead {
    border-bottom: 1px solid colors.$color-grey-border;
    tr {
      font-weight: typography.$font-weight-medium;
    }
  }

  &__unshare {
    vertical-align: bottom;
    display: table-cell;
    position: relative;
    bottom: -30px;
    left: 88px;
  }

  &__fullName {
    width: 14rem !important;
  }

  &__classes {
    width: 16.5rem !important;
  }

  &__views {
    width: 25rem;
  }

  &__abilities-row {
    vertical-align: text-top;
  }

  &__actions {
    width: 11rem !important;
  }

  &__not-initial-column {
    padding-left: 1rem;
  }

  &__not-last-column {
    border-right: 1px solid colors.$color-grey-border;
  }
}

.not-initial-column {
  padding-left: 1rem;
}

.not-last-column {
  border-right: 1px solid colors.$color-grey-border;
}
