@use "../../1-settings/typography";

.c-saved-solututions-list-modal {
  min-width: 65rem;
  font-family: "Montserrat" !important;
  font-size: 14px;
  overflow: scroll;

  &__row-header {
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);

    &__col {
      color: rgba(52, 52, 52, 0.9);
      font-family: "Libre Franklin";
      font-size: 12px;
      font-weight: typography.$font-weight-bold;
    }
  }
}
