.c-solver {
  display: flex;

  > .row {
    height: 100%;
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2050;
  }
  &__classes-container {
    min-height: 100%;
    display: flex;
    overflow-x: scroll;
    background-color: inherit;
    padding-left: 1rem;
    transition: all 0.3s ease-out;
    .logged-in & {
      height: calc(100vh - 72px);
    }

    // Show the scrollbar all the time -
    // Taken from https://codepen.io/stevenlewis/pen/hubpL
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 10px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white; /* should match background, can't be transparent */
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
